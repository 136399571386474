import { useContext, useRef } from "react";
import "./login.css";
import { loginCall } from "../../apiCalls";
import { AuthContext } from "../../context/AuthContext";
import { CircularProgress } from "@mui/material";

export default function Login() {
    const email = useRef();
    const password = useRef();
    const { isFetching, dispatch } = useContext(AuthContext);

    const handleClick = (e) => {
        e.preventDefault();
        loginCall(
          { email: email.current.value, password: password.current.value },
          dispatch
        );
    };
  return (
    <div className="login">
        <div className="loginWrapper">
            <div className="loginLeft">
                <h3 className="loginLogo">FMT</h3>
                <span className="loginDesc">
                    Flächenlagen Management Tool für den Landkreis Leer
                </span>
            </div>
            <div className="loginRight">
                <form className="loginBox" onSubmit={handleClick}>
                    <input
                        placeholder="E-Mail Adresse"
                        type="email"
                        required
                        className="loginInput"
                        ref={email}
                    />
                    <input
                        placeholder="Kennwort"
                        type="password"
                        required
                        minLength="6"
                        className="loginInput"
                        ref={password}
                    />
                    <button className="loginButton" type="submit" disabled={isFetching} color="textPrimary">
                        {isFetching ? (
                            //<CircularProgress />
                            "Lade"
                        ) : (
                            //<CircularProgress color="white" size="20px"/>
                            "Anmelden"
                        )}
                    </button>
                    <span className="loginForgot">Passwort vergessen?</span>
                    <button className="loginRegisterButton">
                        {isFetching ? (
                            //<CircularProgress color="white" size="20px" />
                            "Lade"
                        ) : (
                            "Neuen Zugang Anfordern"
                        )}
                    </button>
                </form>
            </div>
        </div>
    </div>
  )
}
