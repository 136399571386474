import "./sidebar.css"
import {RssFeed, Chat, Group, DirectionsCarFilledOutlined, Bookmark, HelpOutline, WorkOutline, Event, School} from "@mui/icons-material"

export default function Sidebar(props) {
    return (
        <div className="sidebar">
            <div className="sidebarWrapper">
                <ul className="sidebarList">
                    <li className="sidebarListItem">
                        <RssFeed className="sidebarIcon" />
                        <span className="sidebarListItemText">Einsätze</span>
                    </li>
                    <li className="sidebarListItem">
                        <Chat className="sidebarIcon" />
                        <span className="sidebarListItemText">Chats</span>
                    </li>
                    <li className="sidebarListItem">
                        <DirectionsCarFilledOutlined className="sidebarIcon" />
                        <span className="sidebarListItemText">Status</span>
                    </li>
                    <li className="sidebarListItem">
                        <Group className="sidebarIcon" />
                        <span className="sidebarListItemText">Kräfte</span>
                    </li>
                    <li className="sidebarListItem">
                        <Bookmark className="sidebarIcon" />
                        <span className="sidebarListItemText">Bookmarks</span>
                    </li>
                    <li className="sidebarListItem">
                        <HelpOutline className="sidebarIcon" />
                        <span className="sidebarListItemText">Questions</span>
                    </li>
                    <li className="sidebarListItem">
                        <WorkOutline className="sidebarIcon" />
                        <span className="sidebarListItemText">Jobs</span>
                    </li>
                    <li className="sidebarListItem">
                        <Event className="sidebarIcon" />
                        <span className="sidebarListItemText">Termine</span>
                    </li>
                    <li className="sidebarListItem">
                        <School className="sidebarIcon" />
                        <span className="sidebarListItemText">Courses</span>
                    </li>
                </ul>
                {/*<button className="sidebarButton">Zeige mehr</button>*/}
                <hr className="sidebarHr" />
                <ul className="sidebarFriendList">
                    <li className="sidebarFriend">
                        <img className="sidebarFriendImgOnline" src="/assets/person/10wappen.png" alt="" />
                        <span className="sidebarFriendNameOnline">10 - Borkum</span>
                    </li>
                    <li className="sidebarFriend">
                        <img className="sidebarFriendImgOffline" src="/assets/person/11wappen.png" alt="" />
                        <span className="sidebarFriendNameOffline">11 - Hesel</span>
                    </li>
                    <li className="sidebarFriend">
                        <img className="sidebarFriendImgOffline" src="/assets/person/12wappen.png" alt="" />
                        <span className="sidebarFriendNameOffline">12 - Jümme</span>
                    </li>
                    <li className="sidebarFriend">
                        <img className="sidebarFriendImgOffline" src="/assets/person/13wappen.png" alt="" />
                        <span className="sidebarFriendNameOffline">13 - Leer</span>
                    </li>
                    <li className="sidebarFriend">
                        <img className="sidebarFriendImgOffline" src="/assets/person/14wappen.png" alt="" />
                        <span className="sidebarFriendNameOffline">14 - Moormerland</span>
                    </li>
                    <li className="sidebarFriend">
                        <img className="sidebarFriendImgOffline" src="/assets/person/15wappen.png" alt="" />
                        <span className="sidebarFriendNameOffline">15 - Uplengen</span>
                    </li>
                    <li className="sidebarFriend">
                        <img className="sidebarFriendImgOffline" src="/assets/person/20wappen.png" alt="" />
                        <span className="sidebarFriendNameOffline">20 - Bunde</span>
                    </li>
                    <li className="sidebarFriend">
                        <img className="sidebarFriendImgOffline" src="/assets/person/21wappen.png" alt="" />
                        <span className="sidebarFriendNameOffline">21 Jemgum</span>
                    </li>
                    <li className="sidebarFriend">
                        <img className="sidebarFriendImgOffline" src="/assets/person/22wappen.png" alt="" />
                        <span className="sidebarFriendNameOffline">22 - Ostrhauderfehn</span>
                    </li>
                    <li className="sidebarFriend">
                        <img className="sidebarFriendImgOffline" src="/assets/person/23wappen.png" alt="" />
                        <span className="sidebarFriendNameOffline">23 - Rhauderfehn</span>
                    </li>
                    <li className="sidebarFriend">
                        <img className="sidebarFriendImgOffline" src="/assets/person/24wappen.png" alt="" />
                        <span className="sidebarFriendNameOffline">24 - Weener</span>
                    </li>
                    <li className="sidebarFriend">
                        <img className="sidebarFriendImgOffline" src="/assets/person/25wappen.png" alt="" />
                        <span className="sidebarFriendNameOffline">25 Westoverledingen</span>
                    </li>
                </ul>
            </div>
        </div>
    )
}