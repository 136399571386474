import axios from "axios";

export const loginCall = async (userCredential, dispatch) => {
  dispatch({ type: "LOGIN_START" });
  try {
    console.log("Login Rquest");
    const res = await axios.get("https://backend.tetra-statusserver.de/v1/identity", userCredential);
    console.log("Login Rquest -> " + res.data);
    dispatch({ type: "LOGIN_SUCCESS", payload: res.data });
  } catch (err) {
    dispatch({ type: "LOGIN_FAILURE", payload: err });
  }
};