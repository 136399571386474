import Home from "./pages/home/Home";
import Login from "./pages/login/Login";
import Register from "./pages/register/Register";
import {
      BrowserRouter as Router,
      Routes,
      Route,
      Navigate,
    } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "./context/AuthContext";
import { SocketContext } from "./context/SocketContext"
import { io } from 'socket.io-client';

const socket = new io('https://fmt.tetra-statusserver.de:56112')

function App() {
      const { user } = useContext(AuthContext);
      return (
            <Router>
                  <Routes>
                        <Route path="/" element={user ? <Home test={io}/> : <Login />}/>
                        <Route path="/login" element={user ? <Navigate to="/" /> : <Login />}/>
                        <Route path="/register" element={user ? <Navigate to="/" /> : <Register />}/>
                  </Routes>
            </Router>
      );
}

export default App;
